import React from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Button } from "semantic-ui-react"

class NotFoundPage extends React.Component {
  render() {
    const { lang } = this.props
    return (
      <Layout>
        <SEO title="404: Not found" />
        <div style={{ marginTop: "4rem", textAlign: "center" }}>
          <h3>
            {lang === "english" ? "Page not found!" : "¡Página no encontrada!"}
          </h3>
          <div style={{ marginTop: "2rem", fontSize: "1.2em" }}>
            <Button
              style={{ backgroundColor: "#74005F", color: "white" }}
              as={Link}
              to="/"
            >
              {lang === "english"
                ? "Return to the homepage"
                : "Vuelve a la página inicial"}
            </Button>
          </div>
        </div>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(NotFoundPage)
